import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header__menu">
      <Link
        to="/"
        className="header__logo__link"
      >
        <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path fill="#FFF" d="M500 28.8zm-7.9.2c-165.3 2.6-311 85.2-400.2 211-28.5 43.1-50.4 89-67.4 134C8.7 422.8 0 474.8 0 528.8c0 42 5.2 82.8 15 121.8C39.2 734.9 84.6 812.3 137.5 873c37.3 39.3 81 72.6 129.4 98.2-47.3-46.5-169-191.1-89.7-386.9 0 0 84.5-197.3 321.9-196.4h3.7c237.4-.9 321.9 196.4 321.9 196.4 78.2 193.1-38.9 336.3-87.6 384.8 58.8-31.7 110.5-75 152.2-126.6 30.6-40.1 57.5-85.9 77.7-135 21.2-55.5 33-115.7 33-178.6 0-275.9-223.4-499.5-499.2-500l-8.7.1z"/></svg>
      </Link>
      <div></div>
      <nav
        className="header__nav lh-text"
        role="navigation"
      >
        <h1 className="header__nav__title fs-large">{ siteTitle }</h1>
      </nav>
    </div>


  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
